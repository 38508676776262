import React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header";
import Footer from "../components/footer";

const Privacy = () => {

  return(
    <Layout classmain='article'>
    <Seo title="Privacy Policy" description=""/>
      <Header/>
      <main id="content" role="main">
         <div class="container mx-auto">
            <p>This website, including the mobile and online applications, (the “Site“) is operated by Autolytics Technology Group LLC ("Autolytics.io"). This Privacy Statement (the “Statement“) describes
      Autolytics’s collection, use and disclosure of information practices in connection with the Site.
      This Statement applies to the website located at <a href="http://www.Autolytics.io">www.Autolytics.io</a>. Please be aware that
      Autolytics may share information you submit through this Site with affiliates, as described below.</p>
      
      <p>For purposes of clarification, this Statement also describes Autolytics’s collection, use, and
      disclosure practices with respect to any personally identifiable information and Usage Data (as
      defined below) that is provided to Autolytics by any of its third-party partners that display
      Autolytics vehicle listings.</p>
      
      <p>This Statement does not describe Autolytics’s collection, use and disclosure of information
      through means other than this Site (e.g., off-line) or the collection, use and disclosure practices
      of any affiliate or other third parties. For information on a third party’s information collection,
      use and disclosure practices, please review the privacy policy on the applicable third party’s
      website. Also, please note that this Site contains links to other websites. Autolytics is not
      responsible for the websites, content or privacy practices of any third party. By using this Site,
      you acknowledge that you have read and agree to be bound by this Statement and agree that you
      are subject to the Autolytics Terms of Service and California &amp; Nevada Privacy Notice.</p>
      
      <h3 id="collection-of-personally-identifiable-information">Collection of Personally Identifiable Information</h3>
      
      <p>You are not required to provide any personally identifiable information to visit the Site.
      Autolytics automatically collects Usage Data (as described below) and may collect ZIP codes and
      other information in connection with your use of the Site (e.g., the type of car you are looking
      for). Autolytics collects personally identifiable information only when you make it available
      through your use of the Site. You may be required to provide personally identifiable information
      to use certain services on this Site.</p>
      
      <h3 id="passive-information-collection">Passive Information Collection</h3>
      
      <p>The Site automatically collects information and may also customize your visit through technical
      means, including the following:</p>
      
      <ol>
      <li><p>Cookies: Cookies are small text files that are placed on your computer’s hard drive by
      computers (or servers) to identify your computer.</p></li>
      <li><p>Web Beacons: Web beacons (also known as pixel tags, internet tags, clear GIFs, or
      single-pixel GIFs) are electronic images embedded into a webpage.</p></li>
      <li><p>IP Address Logs: Autolytics also logs Internet Protocol (IP) addresses – the location of
      your computer on the internet – and may log device ID and information about your
      browser or operating system.</p></li>
      </ol>
      
      <p>Cookies allow Autolytics to recognize you when you visit the Site, to personalize your experience
      and generally to serve you better. For example, if you enter your name, address or ZIP code on
      one of the Site’s forms, it may be stored in a cookie so you do not have to enter it again on other
      forms on the Site. Cookies and web beacons can tell us what advertising you have seen on the
      Site, which allows Autolytics to offer you a more diverse selection of advertising information and
      may also be used together to measure the effectiveness of Autolytics advertising campaigns.
      Cookies, web beacons and IP addresses are also used to compile data regarding Site usage (e.g.,
      what website you came from, what pages of the Site you visit, how long you were on the Site,
      what type of browser you are using, what website you went to). Web beacons may also be
      included in emails sent by Autolytics to determine when and if such emails are opened or
      forwarded. You can disable cookies or otherwise manage them through tools provided with your
      browser software and with third-party software packages. If you turn off cookies, many of the
      Site’s features will not work as well or at all. Anonymous information regarding use of our Site
      and services that is collected and/or aggregated by or on behalf of Autolytics through the use of
      such technical means or otherwise is referred to in this Statement as “Usage Data.”</p>
      
      <h3 id="information-use">Information Use</h3>
      
      <p>Personally Identifiable Information: Personally identifiable information collected by Autolytics
      through your use of the Site, through your providing an email address to receive promotional or
      other communications via email, or through your providing a mobile number to receive
      coupon(s) or other communications via call, text or SMS, may be stored on servers or in a cookie
      on your computer and may be used by Autolytics for any of the following purposes:</p>
      
      <p>To confirm or fulfill a request that you make (e.g., in order to send you a price quote or other
      information you have requested, Autolytics may share your contact information and content
      interests with a participating Autolytics dealer and/or manufacturer).</p>
      
      <p>To deliver coupons and other marketing communications via text or SMS, which, by providing
      your mobile number to Autolytics, you have expressly consented to receive.</p>
      
      <p>To communicate with you regarding the Autolytics service</p>
      
      <p>To send promotional materials, newsletters or other content via email, text message, or
      otherwise. If you no longer wish to receive a particular email newsletter or other promotion from
      Autolytics, you must follow the opt-out instructions included in such email newsletters, text
      messages, and promotions.</p>
      
      <p>To permit third parties (to whom Autolytics may disclose personally identifiable information
      specifically for direct marketing purposes) to send you promotional or informational messages,
      but if contact is via phone or SMS only if you have first affirmatively agreed to receive such
      messages. You agree that the seller or other third party may use an autodialer to text or call, and
      that a portion of any call may be pre-recorded.</p>
      
      <p>To manage Autolytics’s business and its relationships with affiliates, customers, and other third
      parties to which Autolytics may disclose personally identifiable information pursuant to this
      Statement.</p>
      
      <p>To assist in Site administration, analysis, research, development, optimization and other internal
      purposes, including, without limitation, to compile Usage Data.</p>
      
      <p>Usage Data: Usage Data is used by Autolytics for administrative, analytical, research,
      optimization and other purposes. For example, Autolytics may use statistical analyses of Usage
      Data in order to measure interest in the various areas of the Site, for development purposes and
      to inform advertisers and partners in aggregate and anonymous terms about consumers who click
      on their advertising or other messages. IP addresses are also used for systems administration and
      troubleshooting purposes.</p>
      
      <p>Your use of Autolytics products or services will NOT be limited if you opt out of receiving
      telemarketing calls or texts. However, you agree that Autolytics may send to you, and you cannot
      opt out of, communications of a transactional or relationship nature, including, but not limited to,
      communications regarding fraudulent or illegal activity and changes to this Statement or the
      Autolytics Terms of Service</p>
      
      <h3 id="mobile-specific-information-we-may-collect">Mobile-specific information we may collect</h3>
      
      <p>Autolytics collects mobile information such as your device and hardware IDs and device type, the
      request type, your carrier, your carrier user ID, and the content of your request, which does not
      by itself identify you, though it may be unique or consist of or contain information that you may
      consider personal. Autolytics may collect geo-location information (this may include when you
      use location-based products and services on the Site, and/or turn on location-based services on
      your mobile device). This information may reveal your actual location, such as GPS data, or it
      may not, such as when you submit a partial address to look at a map of the area. All requests may
      be sent through your mobile carrier’s network and your carrier may have access to it. For
      information regarding your carrier’s treatment of your information, please consult your carrier’s
      privacy policies.</p>
      
      <h3 id="information-sharing">Information Sharing</h3>
      
      <p>Autolytics shares the personally identifiable information that you provide with third parties in the
      following cases:</p>
      
      <ol>
      <li><p>Affiliates. Autolytics may share your personally identifiable information with affiliates in
      a manner consistent with this Statement.</p></li>
      <li><p>Requests. If you request information or services through the Site, you agree that
      Autolytics may share personally identifiable information with third parties as may be
      necessary to provide such information to or perform such services for you. For example,
      if you use the Site’s “Email the Dealer” form in a vehicle listing to request a price quote
      from a dealership, Autolytics will share the information you entered with that dealership
      (or its designee). If that vehicle listing was provided by an automobile manufacturer on
      behalf of a dealer, Autolytics may also share the personally identifiable information you
      submit as part of the request with the automobile manufacturer and/or its affiliates.
      Similarly, if through the Site you request additional information from a financial
      institution, Autolytics will share your information with that institution. Personally
      identifiable information also may be included in reports to these third parties, but only
      when you have intended to submit the information to the third party as described above.
      After the information is provided to such third parties, the subsequent use or disclosure of
      such information is subject to those third parties’ privacy policies and practices and
      Autolytics does not control their subsequent use or disclosure of information.</p></li>
      <li><p>Direct Marketing. Except as provided herein, it is Autolytics’s general practice not to
      disclose personally identifiable information of our customers to third parties specifically
      for the third parties’ direct marketing purposes. If you are 18 years of age or older and
      have affirmatively agreed to receive third-party direct marketing materials, Autolytics
      may share personally identifiable information with third parties for direct marketing
      purposes. Although Autolytics will notify all third-party direct marketers that the
      information provided to them is confidential, the subsequent use or disclosure of such
      information is subject to those third parties’ privacy policies and practices.</p></li>
      <li><p>Service Providers. Autolytics may work with third parties that provide services on its
      behalf, including, among others, providers of analytical, attribution, development,
      promotional, research, credit card processing, technical and other services. For example,
      Autolytics may ask that a third party conduct an online survey or sweepstakes or send
      newsletters on its behalf. Similarly, if you make a payment to Autolytics by credit card,
      Autolytics may share your personally identifiable information and credit card information
      with a third party in order to process credit card transactions, or such information may be
      collected directly by the credit card processing service provider on Autolytics’s behalf. In
      such cases, Autolytics asks that the third party maintain your personally identifiable
      information as confidential and use the information only to perform the services specified
      by Autolytics.</p></li>
      <li><p>Email List Opt-Outs. In the event Autolytics sends promotional emails to you on behalf of
      third-party advertisers and you inform Autolytics you wish to opt out of future
      promotional emails from a particular third party, Autolytics may share your email address
      with that third party so the third party may add you to its opt-out lists.</p></li>
      <li><p>Other. Autolytics expressly reserves the right to release any personally identifiable
      information or other information you provide to third parties under the following
      circumstances: (a) when required by law or legal process, (b) to investigate and/or take
      action against illegal activity, suspected abuse or unauthorized use of the Site, (c) to
      protect the property or safety of our users or others, (d) to enforce our Terms of Service
      and (e) in connection with any sale or other transfer of ownership of all or a part of
      Autolytics. Further, if you make any personally identifiable information publicly available
      on the Site, Autolytics reserves the right to disclose such information to third parties,
      including, without limitation, insurance companies and Autolytics’s affiliates and third-
      party distribution partners.</p></li>
      </ol>
      
      <p>Please note that the messages that Autolytics sends to you to confirm, fulfill or follow up on a
      request may incidentally include promotional material or other content from Autolytics or its
      partners. Autolytics reserves the right to share Usage Data with any third party for any reason.</p>
      
      <h3 id="children">Children</h3>
      
      <p>Autolytics believes that it is especially important to protect children’s privacy online. Autolytics
      does not knowingly collect or use any personally identifiable information from children under
      the age of 13 and Autolytics does not knowingly provide any personally identifiable information,
      regardless of its source, to any third party for any purpose whatsoever from visitors younger than
      13 years old. No information should be submitted to or posted at Autolytics by visitors younger
      than 13 years old.</p>
      
      <h3 id="links">Links</h3>
      
      <p>This Site contains links to other sites. While Autolytics encourages our business partners to adopt
      privacy policies that build users’ trust and confidence in the internet, Autolytics is not responsible
      for the privacy practices or the content of websites linked to the Site or any third-party products
      or services offered through the Site.</p>
      
      <h3 id="changes-to-this-statement">Changes to this Statement</h3>
      
      <p>We reserve the right to update and periodically amend this Statement at our discretion and at any
      time. If we make changes to this Statement, amendments will be posted online and the date of
      update will be included. Your continued use of Autolytics after any such update indicates your
      agreement to the same.</p>
      
      <p>Notice to California Residents – Your California Privacy Rights
      If you are a California or Nevada resident, state law may provide you with additional rights
      regarding our use of your personal information. To learn more about your California or Nevada
      privacy rights, please visit Autolytics’s California &amp; Nevada Privacy Statement.</p>
      
      <h3 id="notice-to-european-residents">Notice to European Residents</h3>
      
      <p>This Site is intended for use solely in the United States and Canada by U.S. and Canadian
      residents. Autolytics makes no claims that the Site is appropriate for use outside of the United
      States or Canada. Autolytics does not knowingly collect or solicit personal information from
      residents of the European Union. If you believe that we may have any information from or about
      an EU resident, please email us at <a href="mailto:contact@autolytics.io">contact@autolytics.io</a>. No information should be submitted to or
      posted at Autolytics by any EU resident.</p>
      
      <h3 id="collection-of-information-by-third-parties">Collection of Information by Third Parties</h3>
      
      <p>As noted above, affiliates, third parties who place advertisements on this website, and other
      third-party service providers may collect and use information about your visits to and use of this
      website through the use of cookies, web beacons and other similar technologies. For example,
      we work with companies that collect statistical information regarding Site usage and provide
      related analytical services. We may work with third-party advertising companies that use cookies
      and web beacons to serve advertisements when you come to the Site and to generate statistics
      and metrics related to the delivery of such advertisements. We also may work with third-party
      advertising platforms and networks that use cookies, web beacons and other technologies to
      collect non-personally identifiable information about your visits to and interactions with this Site
      and other websites in order to provide targeted emails and other advertisements about goods and
      services of interest to you across networks of websites (which may or may not include this Site).
      These third parties may also use this information for determining or predicting the characteristics
      and preferences of their respective advertising audiences, for measuring the effectiveness of their
      advertising and for other purposes.</p>
      
      <p>Third-party service providers may also collect information from your devices and may combine
      personal and non-personal information about you with information from other sources including
      your visits to this and other websites, mobile websites and/or mobile applications across your
      various devices. These activities may enable them to identify you in order to provide content and
      advertisements about goods and services of interest to you across those various devices.</p>
      
      <p>If you would like to obtain more information about the practices of some of these parties, or if
      you would like to make choices about their use of your information, please click here:</p>
      
      <p><a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a></p>
      
      <p>More information and additional consumer choice options may be found at this link:</p>
      
      <p><a href="https://www.networkadvertising.org/managing/opt_out.asp">http://www.networkadvertising.org/managing/opt_out.asp</a></p>
      
      <p>Canadian users may find information and consumer choice options at this link:</p>
      
      <p><a href="https://youradchoices.ca/">https://youradchoices.ca/</a></p>
      
      <p>Information and additional consumer choice options regarding mobile device tracking may be
      found at these links:</p>
      
      <p>AAID reset and opt-out:
      <a href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en">https://support.google.com/googleplay/android-developer/answer/6048248?hl=en</a></p>
      
      <p>Contact Information</p>
      
      <p>If you have any questions or comments about this Privacy Statement, the ways in which we
      collect and use your personal information, your choices and rights regarding such use, or wish to
      exercise your rights under California, Nevada or other state law, please do not hesitate to contact
      us at:</p>
      
      <p>Email: <a href="mailto:contact@autolytics.io">contact@autolytics.io</a></p>
      
      <p>©2022 Autolytics Technology Group LLC</p>
      
      <p>Last Updated — May 20, 2021</p>
      
          </div>
        </main>
          <Footer/>
    </Layout>
  )
}

export default Privacy